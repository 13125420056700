<template>
  <div class="w-full h-screen">
    <section class="bg-black h-9/10">
      <StaticHeader class="absolute top-0 left-0 z-10 mt-8" />
      <img class="absolute left-0 right-0 object-cover h-9/10" src="../assets/washington-hero.jpg" />
      <div class="flex items-center justify-center w-full h-full">
        <a href="https://opensea.io/collection/washington-v3" target="_blank" class="z-10 w-2/5 max-w-lg p-4 text-lg font-bold text-center text-white bg-blue-600 rounded-none hover:bg-blue-400 mt-72">
          View on OpenSea
        </a>
      </div>
      <div class="custom-shape-divider-bottom-1639318827">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
    </section>
    <section class="flex justify-center w-full px-16 py-12 align-middle bg-black">
      <div class="grid w-full max-w-6xl grid-cols-1 gap-6 md:grid-cols-2">
        <div class="text-white md:pt-16">
          <h1 class="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600">We the People</h1>
          <h2 class="mt-2 text-3xl">Washington Series</h2>
          <p class="mt-12 text-gray-400">
            We the People is a set of 10,000 NFTs created by a group of digital artists and crypto enthusiasts. This project was inspired by ConstitutionDAO. We the People aims to promote the
            ideologies of the Constitution and pay tribute to the crypto and DAO community.
            <br />
            We the people, by the people, for the people.
          </p>
        </div>
        <div class="py-4 text-center">
          <img class="xs:w-80 md:w-96 md:max-w-md" src="../assets/various.gif" />
        </div>
      </div>
    </section>
    <!-- <div class="pt-4 text-2xl text-center text-gray-200 bg-black">Top NFTs by rarity</div>
    <section class="flex justify-center py-12 bg-black">
      <div class="grid max-w-6xl grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-5">
        <div v-for="nft of nfts" :key="nft.tokenId">
          <img class="rounded-lg" :src="`https://assets.mita.network/nft/00_washington/images/${nft.tokenId}.jpg`" />
          <a :href="`https://opensea.io/assets/matic/0x0eb4466ee918154ca359d34e5f1aa5060a337c9e/${nft.tokenId}`" target="_blank" class="flex px-2 -mt-6 text-white align-middle bg-black opacity-70">
            <div class="flex-grow">Rarity #{{ nft.rank }}</div>
            <div class="items-center self-center px-2 align-middle"><Icon icon="cryptocurrency:matic"></Icon></div>
            <span>View</span>
          </a>
        </div>
      </div>
    </section> -->
    <section class="pb-12 bg-black">
      <p class="mb-8 text-lg text-center text-gray-200">The roadmap consists of our current milestones and our goals towards where we want to take MITA Lab.</p>
      <Timeline />
    </section>

    <section
      class="flex justify-center w-full min-w-full p-10 bg-center bg-cover h-96"
      :style="{
        backgroundImage: `url(${require('../assets/hero-background.jpg')})`,
      }"
    >
      <div class="grid max-w-6xl grid-cols-1 md:grid-cols-2">
        <div class="w-full max-w-md md:text-right md:place-self-center">
          <h1 class="z-10 text-5xl font-bold text-gray-200">Join the community</h1>
          <p class="mt-8 text-gray-200"></p>
        </div>
        <a href="https://discord.gg/SPr5DVJ4Au" target="_blank" class="flex self-center md:justify-center min-w-min">
          <button class="z-10 w-2/5 p-4 mt-2 text-lg font-bold text-white bg-blue-600 rounded-none hover:bg-blue-400">Join Discord</button>
        </a>
      </div>
    </section>
    <section class="flex justify-center px-12 pt-20 pb-12 bg-black">
      <div class="w-full max-w-6xl md:flex">
        <div class="flex self-center order-last gap-4 mb-8 md:flex-1">
          <a href="https://twitter.com/mita_lab" target="_blank" class="box-border flex justify-center w-10 h-10 align-middle duration-300 border border-white border-solid hover:bg-white">
            <Icon class="self-center text-white hover:text-black" icon="bi:twitter" />
          </a>
          <a href="https://discord.gg/SPr5DVJ4Au" target="_blank" class="box-border flex justify-center w-10 h-10 align-middle duration-300 border border-white border-solid hover:bg-white">
            <Icon class="self-center text-white hover:text-black" icon="bi:discord" />
          </a>
          <a href="https://instagram.com/mita_lab_official" target="_blank" class="box-border flex justify-center w-10 h-10 align-middle duration-300 border border-white border-solid hover:bg-white">
            <Icon class="self-center text-white hover:text-black" icon="ant-design:instagram-filled" />
          </a>
          <a href="https://t.me/+uCYBM3ybitYyYTJk" target="_blank" class="box-border flex justify-center w-10 h-10 align-middle duration-300 border border-white border-solid hover:bg-white">
            <Icon class="self-center text-white hover:text-black" icon="fa-brands:telegram-plane" />
          </a>
        </div>
        <div class="md:flex-1">
          <div class="flex">
            <div>
              <img class="h-10" src="../assets/mita.svg" />
            </div>
            <div class="self-center flex-grow text-2xl font-bold text-white"></div>
          </div>
          <div class="mt-4 text-gray-200">©2021 MitaLab. All rights reserved.</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import StaticHeader from "../components/StaticHeader.vue";
import Timeline from "../components/Timeline.vue";
import { Icon } from "@iconify/vue";

const nfts = ref([
  {
    tokenId: 9568,
    rank: 1,
  },
  {
    tokenId: 4727,
    rank: 2,
  },
  {
    tokenId: 5905,
    rank: 3,
  },
  {
    tokenId: 3043,
    rank: 4,
  },
  {
    tokenId: 8206,
    rank: 5,
  },
  {
    tokenId: 5535,
    rank: 6,
  },
  {
    tokenId: 7303,
    rank: 7,
  },
  {
    tokenId: 7110,
    rank: 8,
  },
  {
    tokenId: 9700,
    rank: 9,
  },
  {
    tokenId: 9665,
    rank: 10,
  },
]);
</script>

<style>
.custom-shape-divider-bottom-1639318827 {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1639318827 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1639318827 .shape-fill {
  fill: black;
}
</style>
