<template>
  <div class="container relative flex flex-col px-6 mx-auto space-y-8">
    <div class="absolute inset-0 z-0 w-1 h-full bg-white shadow-md left-17 md:mx-auto md:right-0 md:left-0"></div>
    <div class="relative z-10">
      <div class="w-8 h-8 ml-8 bg-pink-400 md:absolute md:mx-auto md:left-0 md:right-0 top-8 left-8"></div>
      <div class="timeline-container">
        <div class="timeline-pointer" aria-hidden="true"></div>
        <div class="p-6 bg-white rounded-md shadow-md">
          <span class="text-sm font-bold tracking-wide text-pink-400">Dec 7, 2021</span>
          <h1 class="pt-1 text-2xl font-bold">Washington NFT launch</h1>
          <p class="pt-1">
            MITA Lab released a total of 10,000 UNIQUE NFTs on OpenSea. This project was inspired by ConstitutionDAO, and our group has dedicated all their hearts and souls to this collection. We
            wanted to create something that has not just high quality, but also depth. We hope that you enjoy our works.
          </p>
        </div>
      </div>
    </div>
    <div class="relative z-10">
      <div class="w-8 h-8 ml-8 bg-pink-400 md:absolute md:mx-auto md:left-0 md:right-0 top-8 left-8"></div>
      <div class="timeline-container timeline-container-left">
        <div class="timeline-pointer timeline-pointer-left" aria-hidden="true"></div>
        <div class="p-6 bg-white rounded-md shadow-md">
          <span class="text-sm font-bold tracking-wide text-pink-400">Dec 2021</span>
          <h1 class="pt-1 text-2xl font-bold">Build our Community</h1>
          <p class="pt-1">
            We set up our Twitter account to keep people posted on updates for our projects. We have also created a Telegram group and a Discord server to invite people join the community. Community
            members could share their ideas, interact with each other, and also vote for our next collection design!
          </p>
        </div>
      </div>
    </div>
    <div class="relative z-10">
      <div class="w-8 h-8 ml-8 bg-pink-400 md:absolute md:mx-auto md:left-0 md:right-0 top-8 left-8"></div>
      <div class="timeline-container">
        <div class="timeline-pointer" aria-hidden="true"></div>
        <div class="p-6 bg-white rounded-md shadow-md">
          <span class="text-sm font-bold tracking-wide text-pink-400">Dec 2021</span>
          <h1 class="pt-1 text-2xl font-bold">MITA Lab x Artists</h1>
          <p class="pt-1">We have worked with some amazing artists in the NFT community. We are planning on doing a collaboration with artists for our next collection, stay tuned!!!</p>
        </div>
      </div>
    </div>
    <div class="relative z-10">
      <div class="w-8 h-8 ml-8 bg-pink-400 md:absolute md:mx-auto md:left-0 md:right-0 top-8 left-8"></div>
      <div class="timeline-container timeline-container-left">
        <div class="timeline-pointer timeline-pointer-left" aria-hidden="true"></div>
        <div class="p-6 bg-white rounded-md shadow-md">
          <span class="text-sm font-bold tracking-wide text-pink-400">Dec 2021</span>
          <h1 class="pt-1 text-2xl font-bold">MITA Lab Community Member Event</h1>
          <p class="pt-1">
            We´re so grateful for each one who joined our community to support us. We will host community event periodically to appreciate our members and to carry our community forward!
          </p>
        </div>
      </div>
    </div>
    <div class="relative z-10">
      <div class="w-8 h-8 ml-8 bg-pink-400 md:absolute md:mx-auto md:left-0 md:right-0 top-8 left-8"></div>
      <div class="timeline-container">
        <div class="timeline-pointer" aria-hidden="true"></div>
        <div class="p-6 bg-white rounded-md shadow-md">
          <span class="text-sm font-bold tracking-wide text-pink-400">Dec 2021</span>
          <h1 class="pt-1 text-2xl font-bold">Christmas is just around the corner!</h1>
          <p class="pt-1">Wink Wink.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@layer components {
  .timeline-container {
    @apply relative pt-2 md:pl-28 md:pt-0 md:w-1/2 md:ml-auto md:pl-16;
  }
  .timeline-container-left {
    @apply md:ml-0 md:mr-auto md:pl-0 md:pr-16;
  }
  .timeline-pointer {
    @apply absolute inset-0 w-4 h-4 transform rotate-45 top-12 bg-white left-10 md:top-11 md:left-26 md:left-14;
  }
  .timeline-pointer-left {
    @apply md:left-auto md:right-14;
  }
}
</style>
